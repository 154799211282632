<template>
    <div class="page">
        <div class="page__title">
            <nav-back />
            App Module
        </div>
        <div class="form-group">
            <label>DeploymentEnvironment:</label>
            <b-dropdown :text="deploymentEnvText">
                <b-dropdown-item
                    v-for="(option, i) of envOptions" :key="i" @click="onDeploymentEnvValueChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="form-group">
            <label>StoreModuleEnabled:</label>
            <b-dropdown :text="storeModuleText">
                <b-dropdown-item
                    v-for="(option, i) of options" :key="i" @click="onStoreModuleValueChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="form-group">
            <label>CatalogModuleEnabled:</label>
            <b-dropdown :text="catalogModuleText">
                <b-dropdown-item
                    v-for="(option, i) of options" :key="i" @click="onCatalogModuleValueChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="form-group">
            <label>EventModuleEnabled:</label>
            <b-dropdown :text="eventModuleText">
                <b-dropdown-item
                    v-for="(option, i) of options" :key="i" @click="onEventModuleValueChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="form-group">
            <label>ProfileModuleEnabled:</label>
            <b-dropdown :text="profileModuleText">
                <b-dropdown-item
                    v-for="(option, i) of options" :key="i" @click="onProfileModuleValueChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        
        <ui-button class="w-25 my-3" :disabled="locked" @clicked="onSaveClick">
            Сохранить
        </ui-button>
    </div>
</template>

<script>
import bus from '@/plugins/bus';
import { mapActions } from 'vuex';

export default {
    components: {
        UiButton: () => import('../../components/ui/UiButton'),
        NavBack: () => import('../../components/navigation/NavBack'),
    },
    data: () => ({
        locked: false,
        
        deploymentEnvValue: 0,
        storeModuleEnabled: 0,
        catalogModuleEnabled: 0,
        eventModuleEnabled: 0,
        profileModuleEnabled: 0,
        
        options: [
            { value: 1, name: 'Включено' },
            { value: 0, name: 'Выключено' },
        ],
    }),
    computed: {
        config() {
            return this.$store.getters['firebase/appModule'];
        },
        envOptions() {
            return this.$store.getters['firebase/getConfigEnvs'].map((env, index) => ({
                value: index,
                name: env
            }));
        },
        deploymentEnvText() {
            return this.envOptions?.find(opt => opt.value === this.deploymentEnvValue)?.name || 'Ожидание';
        },
        storeModuleText() {
            return this.options.find(opt => opt.value === this.storeModuleEnabled).name;
        },
        catalogModuleText() {
            return this.options.find(opt => opt.value === this.catalogModuleEnabled).name;
        },
        eventModuleText() {
            return this.options.find(opt => opt.value === this.eventModuleEnabled).name;
        },
        profileModuleText() {
            return this.options.find(opt => opt.value === this.profileModuleEnabled).name;
        }
    },
    async created() {
        await this.getConfig();
        
        this.onConfigChanged();
        
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'saveAppModuleConfig']),
        onConfigChanged() {
            const envName = this.config?.['DeploymentEnvironment'] || 'Default';
            this.deploymentEnvValue = this.envOptions.find(opt => opt.name === envName)?.value || 0;
            
            this.storeModuleEnabled = +this.config?.['StoreModuleEnabled'] || 0;
            this.catalogModuleEnabled = +this.config?.['CatalogModuleEnabled'] || 0;
            this.eventModuleEnabled = +this.config?.['EventModuleEnabled'] || 0;
            this.profileModuleEnabled = +this.config?.['ProfileModuleEnabled'] || 0;
        },
        onStoreModuleValueChanged(event) {
            this.storeModuleEnabled = +event.target.dataset.value;
        },
        onCatalogModuleValueChanged(event) {
            this.catalogModuleEnabled = +event.target.dataset.value;
        },
        onEventModuleValueChanged(event) {
            this.eventModuleEnabled = +event.target.dataset.value;
        },
        onProfileModuleValueChanged(event) {
            this.profileModuleEnabled = +event.target.dataset.value;
        },
        onDeploymentEnvValueChanged(event) {
            this.deploymentEnvValue = +event.target.dataset.value;
        },
        async onSaveClick() {
            this.locked = true;
            
            const response = await this.saveAppModuleConfig({
                DeploymentEnvironment: this.deploymentEnvText,
                StoreModuleEnabled: !!this.storeModuleEnabled,
                CatalogModuleEnabled: !!this.catalogModuleEnabled,
                EventModuleEnabled: !!this.eventModuleEnabled,
                ProfileModuleEnabled: !!this.profileModuleEnabled,
            });
            
            this.locked = false;
            
            if (!response.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.form-group {
    display: grid;
    align-items: end;
    grid-template-columns: .25fr .1fr;
    text-align: left;
    
    label {
        margin-right: 8px;
    }
}
</style>
